<template>
  <div id="container" style="height: calc(100% - 40px)"></div>
</template>

<script>
import ChartService from '../services/chart.service'
import ZigZag from '../technical/zigzag';
import srlines from '../technical/srLines';

let chart;

export default {
  name: "ChartTest",
  props: ['symbol'],
  data() {
    return {
      isShowingSR: false,
      zigzag: [],
      supportLines: [],
      resistanceLines: []
    }
  },
  methods: {
    toggleSR() {

      if (!this.isShowingSR) {
        this.isShowingSR = true;
        this.supportLines.forEach((line, index) => {

          chart.yAxis[0].addPlotLine(line);
        });

        this.resistanceLines.forEach((line, index) => {

          chart.yAxis[0].addPlotLine(line);
        });
      } else {
        this.isShowingSR = false;
        this.supportLines.forEach((line, index) => {
          chart.yAxis[0].removePlotLine(line.id);
        })

        this.resistanceLines.forEach((line, index) => {
          chart.yAxis[0].removePlotLine(line.id);
        })
      }
    },
    extraData() {

      const zigzag = ZigZag(this.candles, 3);
      const {support, resistance} = srlines(JSON.parse(JSON.stringify(this.candles)));

      let supportLines = support.sort((a, b) => b.strength - a.strength).slice(0, 20).map((line, index) => ({
        value: line.avg,
        id: `s-${index}`,
        color: 'rgba(4,128,91,0.3)',
        width: 2,
        zIndex: 10,
      }));

      let resistanceLines = resistance.sort((a, b) => b.strength - a.strength).slice(0, 20).map((line, index) => ({
        id: `r-${index}`,
        value: line.avg,
        color: 'rgba(208,18,53,0.3)',
        width: 2,
        zIndex: 10,
      }));

      this.zigzag = zigzag;
      this.supportLines = supportLines;
      this.resistanceLines = resistanceLines;
    },
    drawChart() {

      const supportLines = JSON.parse(JSON.stringify(this.supportLines));
      const resistanceLines = JSON.parse(JSON.stringify(this.resistanceLines));

      const symbol = this.symbol;
      const candles = this.candles.map(candle => [new Date(candle.date).getTime(), candle.open, candle.high, candle.low, candle.close]);

      const volume = this.candles.map(candle => ({
        x: new Date(candle.date).getTime(),
        y: candle.volume,
        color: candle.close > candle.open ? 'rgba(4,128,91, .8)' : 'rgba(208,18,53,0.8)'
      }))

      const patterns = this.patterns.map(pattern => {

        const components = pattern.components;

        return {
          draggable: false,
          shapes: [{
            animation: false,
            type: 'path',
            points: [{
              yAxis: 0,
              xAxis: 0,
              x: new Date(components.resistance.startDate).getTime(),
              y: components.resistance.startValue
            }, {
              yAxis: 0,
              xAxis: 0,
              x: new Date(components.resistance.endDate).getTime(),
              y: components.resistance.startValue
            }],
            strokeWidth: 4,
            stroke: 'rgb(208,18,53)',
            dashStyle: 'solid'
          }, {
            draggable: false,
            animation: false,
            type: 'path',
            points: [{
              yAxis: 0,
              xAxis: 0,
              x: new Date(components.support.startDate).getTime(),
              y: components.support.startValue
            }, {
              yAxis: 0,
              xAxis: 0,
              x: new Date(components.support.endDate).getTime(),
              y: components.support.endValue
            }],
            strokeWidth: 4,
            stroke: 'rgb(4,128,91)',
            dashStyle: 'solid'
          }]
        }
      })

      chart = Highcharts.chart('container', {
        title: false,
        legend: false,
        plotOptions: {
          series: {
            hover: false
          },
          candlestick: {
            groupPadding: 5,
            color: 'rgb(208,18,53)',
            lineColor: 'rgb(208,18,53)',
            upColor: 'rgb(4,128,91)',
            upLineColor: 'rgb(4,128,91)'
          }
        },
        tooltip: {
          shared: false,
          useHTML: true,
          backgroundColor: 'transparent',
          formatter: function () {
            var s = '<div style="font-size: 14px;">' + Highcharts.dateFormat('%A, %b %e, %Y', this.x) + ' \u00A0'; // Format the date
            let point = this.point;
            return `<div style="border-radius: 5px; padding: 5px 10px; backdrop-filter: blur(2px); background: rgba(0, 0, 0, 0.05); color: var(--textColor)">${s}<span>O</span><span style="color: ${point.open > point.close ? 'rgb(4,128,91)' : 'rgb(208,18,53)'}">${point.open}</span> \u00A0<span>H</span><span style="color: ${point.open > point.close ? 'rgb(4,128,91)' : 'rgb(208,18,53)'}">${point.high}</span> \u00A0<span>L</span><span style="color: ${point.open > point.close ? 'rgb(4,128,91)' : 'rgb(208,18,53)'}">${point.low}</span> \u00A0<span>C</span><span style="color: ${point.open > point.close ? 'rgb(4,128,91)' : 'rgb(208,18,53)'}">${point.close}</span></div></div>`;
          },
          shadow: false,
          positioner: function (labelWidth, labelHeight, point) {
            return {
              x: 5,
              y: 5
            };
          },
        },
        xAxis: {
          ordinal: true,
          type: 'datetime', // Specify that the x-axis is a datetime axis
          tickInterval: null,
          dateTimeLabelFormats: {
            day: '%e %b %Y', // Format for days
            week: '%e %b %Y', // Format for weeks
            month: '%b %Y', // Format for months
            year: '%Y' // Format for years
          },
          crosshair: {
            snap: true,
            color: 'var(--notficationBorderColor)'
          },
          labels: {
            style: {
              color: 'var(--textColor)'
            }
          },
          gridLineColor: '#393b434f'
        },
        yAxis: [{
          labels: {
            style: {
              color: 'var(--textColor)'
            }
          },
          plotLines: [],
          opposite: true,
          title: false,
          height: '85%',
          crosshair: {
            snap: false,
            color: 'var(--notficationBorderColor)'
          },
          gridLineColor: '#393b434f',
        }, {
          opposite: true,
          labels: {
            style: {
              color: 'var(--textColor)'
            },
            x: 10
          },
          title: {
            text: false
          },
          top: '87%',
          height: '13%',
          offset: 0,
          gridLineColor: '#393b434f',
          lineWidth: 0
        }],
        chart: {
          events: {
            load: function () {
              const chart = this;

              const text = symbol + ',1D'; // Your watermark text
              const x = chart.plotLeft + (chart.plotWidth / 2); // Center x position
              const y = chart.plotTop + (chart.plotHeight / 2); // Center y position

              // Add the watermark text
              chart.renderer.text(text, x, y)
                  .css({
                    color: 'rgba(255, 255, 255, 0.15)', // Light color for watermark
                    fontSize: '64px',
                    textAlign: 'left',
                    transform: 'translateX(-10%)'
                  })
                  .attr({
                    zIndex: 5 // Ensure it's above other elements
                  })
                  .add();


              let isDragging = false;
              let startY;
              let extremes;

              Highcharts.addEvent(chart.container, 'mousedown', function (e) {

                const chartX = e.chartX;
                const plotWidth = parseFloat(chart.plotBackground.element.getAttribute('width'));

                if (chartX > plotWidth) {

                  if (!isDragging) {
                    const yAxis = chart.yAxis[0];
                    extremes = yAxis.getExtremes();
                    isDragging = true;
                    startY = e.chartY;
                  }
                }
              });

              Highcharts.addEvent(document, 'mousemove', function (e) {
                if (isDragging) {

                  const deltaY = (e.chartY - startY) / 10;
                  const yAxis = chart.yAxis[0];
                  yAxis.setExtremes((extremes.min - deltaY), extremes.max + (deltaY))


                  const lastExtremes = yAxis.getExtremes();
                  if (lastExtremes.min === undefined || lastExtremes.max === undefined) {
                    yAxis.setExtremes(extremes.dataMin, extremes.dataMax)
                  }
                }
              });

              Highcharts.addEvent(document, 'mouseup', function () {
                isDragging = false;
              });

            }
          },
          animation: false,
          backgroundColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 1,
              y2: 1
            },
            stops: [
              [0, '#1f1d1e'],
              [1, 'rgb(42,39,41)']
            ]
          },
          panKey: false,
          panning: {
            enabled: true,
            type: 'xy'
          },
          panType: 'xy',
          pinchType: 'xy',
          zooming: {
            mouseWheel: {
              enabled: true,
              type: 'x'
            }
          },
        },
        rangeSelector: {
          selected: 0.4
        },
        series: [{
          type: 'candlestick',
          name: null,
          pointWidth: 1,
          clip: false,
          pointRange: 10,
          data: candles,
        }, {
          animation: false,
          type: 'column',
          name: 'Volume',
          borderWidth: 0,
          borderRadius: 0,
          data: volume,
          yAxis: 1,
        }],
        annotations: patterns,
      });
    }
  },
  async mounted() {
    const {candles, patterns} = await ChartService.getChartBySymbol(this.symbol);
    this.candles = candles;
    this.patterns = patterns;


    try {

      this.$nextTick(() => {
        this.extraData();

        this.drawChart();
      })
    } catch (e) {
      console.log(e)
    }
  }
}
</script>

<style scoped>

</style>
