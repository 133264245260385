
import axios from "axios";

let config = {
    baseURL: process.env.VUE_APP_API_BASE_URL + '/admin/v1',
    withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
    function(config) {

        config.headers.ENTITY = 'admin';
        return config;
    },
    function(error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function(response) {

        return response.data;
    },
    function(error) {

        if (error.response.data.error) {

        }
        return Promise.reject(error);
    }
);

window.axios = _axios;
