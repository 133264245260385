const headers = [{
    label: 'Subject',
}, {
    label: 'Send to'
}, {
    label: 'Timing',
    sortValue: 'timing'
}, {
    label: 'Was sent',
    sortValue: 'wasSent'
},
    {
        label: 'Actions',
        sortValue: null
    }];

export default headers;
