<template>
  <label :for="labelId">
    <input type="checkbox" :name="name" ref="checkbox" :id="labelId" v-bind="$attrs" :value="value" :checked="defaultChecked || isChecked"  @change="handleChange">
    {{label}}
  </label>
</template>

<script>
export default {
  name: "MBPCheckbox",
  props: ['labelId', 'label', 'value', 'name', 'values', 'modelValue', 'defaultChecked'],
  methods: {
    handleChange (event) {
      event.stopPropagation();
      this.$emit('update:modelValue', this.$refs.checkbox.checked)
    }
  },
  computed: {
    isChecked() {
      return this.values ? this.values.includes(this.value) : false;
    }
  },
}
</script>

<style scoped lang="scss">
label {
  cursor: pointer;
  flex: 0 1 fit-content;
}

input, label {
  user-select: none;
}
</style>
