<template>
  <main>
    <nav id="nav">
      <div v-for="(link, linkIdx) in navBarLinks">
        <router-link :to="link.link" class="navLink desktop notActive" v-if="link.text !== 'divide'">
          <i :class="link.icon"/>
          <span>{{ link.text }}</span>
        </router-link>
        <div class="divide" v-if="link.text === 'divide'"/>
      </div>

    </nav>
    <div id="view">
      <router-view/>
    </div>
  </main>
</template>

<script>

export default {
  data() {
    return {
      navBarLinks: [{
        icon: 'ri-newspaper-line',
        text: 'News & notifications',
        link: '/notifications'
      },
        {
          icon: 'ri-mail-line',
          text: 'Emails',
          link: '/emails'
        }, {
          icon: 'ri-line-chart-line',
          text: 'Trades',
          link: '/trades'
        },
        {
          icon: 'ri-user-line',
          text: 'Users & subscriptions',
          link: '/users'
        },
        {
          text: 'divide'
        },
        {
          icon: 'ri-line-chart-line',
          text: 'Patterns (TESTING)',
          link: '/patterns'
        },
      ]
    }
  },
  beforeMount() {
    document.documentElement.setAttribute('data-theme', 'dark')
  }
}
</script>

<style lang="scss">

@import "assets/style/general";

.p-datepicker-panel {
  background: var(--bgColor) !important;
  border: 1px solid var(--notficationBorderColor) !important;
  border-radius: 8px !important;
  box-sizing: content-box !important;
}

.p-datepicker-calendar-container {
  padding: 10px !important;
  border-bottom: 1px solid var(--notficationBorderColor);
  box-sizing: content-box !important;

}

.p-datepicker-day {
  transition: all .2s ease-in !important;
  padding: 5px !important;

  &:hover {
    background: var(--tableHoverColorOpacity) !important;
  }
}

.p-datepicker-day.p-disabled {
  opacity: .6;
}

.p-datepicker-today {
  .p-datepicker-day {
    background: var(--lightYellowOpacity) !important;
    color: var(--lightYellow) !important;
  }
}

.p-datepicker-day.p-datepicker-day-selected {
  background: var(--mbpGreenOpacity) !important;
  color: var(--mbpGreen) !important;
}

.p-datepicker-header {
  margin-bottom: 10px;
}

.p-datepicker-title {
  button {
    font-size: 16px !important;
  }
}

.p-datepicker-weekday,
.p-datepicker-day-cell {
  padding: 5px !important;
}

@import "./assets/style/general";

main {
  display: flex;
  height: 100%;
}

#view {
  width: calc(100% - 160px);
  overflow-y: auto;
}

#nav {

  .divide {
    background: var(--notficationBorderColor);
    height: 2px;
    width: 210px;
  }

  top: 0;
  z-index: 11;
  display: flex;
  gap: 5px;
  background: var(--navBarBgColor);
  border-bottom: 2px solid var(--notficationBorderColor);
  transition: all 0.15s cubic-bezier(1, 0.5, 0.8, 1);
  flex-direction: column;
  align-items: flex-start;
  border-bottom: unset;
  border-right: 2px solid #111;
  width: 160px;
  padding: .5rem;
  height: calc(100vh - 1rem);
  box-sizing: content-box;
  overflow: hidden;
  white-space: nowrap;

  @media(max-width: 660px) {
    width: 100%;
    height: 26px;
  }

  &[dir="rtl"] {
    right: 0;
    left: unset;
    border-right: unset;
    border-left: 2px solid #111;
  }

  a {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 5px;
    transition: all 0.15s cubic-bezier(1, 0.5, 0.8, 1);

    span {
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}



</style>
