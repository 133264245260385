<template>
  <section>

    <div id="form" :key="formKey">
      <h1>Send an email</h1>
      <div class="field">
        <MBPTextarea label="English subject (keep it short)" v-model="email.subject.en"/>
      </div>

      <div class="field">
        <MBPTextarea dir="rtl" label="Hebrew subject (keep it short)" v-model="email.subject.he"/>
      </div>

      <div class="field">
        Send to:
        <div style="height: 5px;"/>

        <div class="row wrap">
          <MBPCheckbox v-for="(group) in sendTo" style="margin-bottom: 0" :key="group.value" :values="this.email.sendTo"
                       :value="group.value" name="tags"
                       @change="sendToAddRemove($event?.target?.value)" :label-id="group.value" :label="group.label"/>
        </div>
      </div>

      <div class="field">
        Attachments:
        <div style="height: 5px;"/>


        <div class="field">

          <!-- attachments list-->
          <div class="row attachment" v-for="(file, fileIndex) in email.attachments" :key="file.name">
            {{ file.name }} ({{ file.type.toUpperCase() }})
            <i @click="email.attachments.splice(fileIndex, 1)" class="ri-close-line"/>
          </div>

          <MBPButton label="Select file" class="action outline" @click="initUploadModal"/>
        </div>
        <input style="display: none;" id="file" type="file" @change="handleFileChange"
               accept="image/*,.pdf,.csv,.xls,.xlsx,.doc,.docx" ref="file"/>

      </div>

      <div class="field">
        Timing:
        <div style="height: 5px;"/>
        <DatePicker showTime hourFormat="24" :minDate="new Date()" v-model="email.timing" dateFormat="dd/mm/yy"/>
      </div>

      <div class="field">
        English content:
        <div style="height: 5px;"/>
        <quill-editor theme="snow" :options="quillOptions" contentType="html" v-model:content="email.content.en"/>
      </div>

      <div class="field">
        Hebrew content:
        <div style="height: 5px;"/>
        <quill-editor theme="snow" :options="quillOptions" contentType="html" v-model:content="email.content.he"
                      @ready="onEditorReady"/>
      </div>

      <div class="field">
        <MBPButton label="Send email" class="action large" @click="sendEmail"/>
        <MBPButton label="Send test" class="danger large" style="margin-left: 1rem;" @click="sendEmail(true)"/>
      </div>

    </div>

  </section>
</template>

<script>


import NotificationService from '../../../services/notifications.service';
import MBPInput from "../../../components/ui/controls/MBPInput";
import MBPCheckbox from "../../../components/ui/controls/MBPCheckbox";
import MBPRadio from "../../../components/ui/controls/MBPRadio";

/** deps and libs **/
import {QuillEditor} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import MBPButton from "../../../components/ui/controls/MBPButton";

import DatePicker from 'primevue/datepicker';
import MBPTextarea from "../../../components/ui/controls/MBPTextarea";


export default {
  name: "PostEmail",
  components: {MBPTextarea, MBPButton, MBPRadio, MBPCheckbox, MBPInput, QuillEditor, DatePicker},
  data() {
    return {
      formKey: 1,
      sendTo: [{
        value: 'inTrial',
        label: 'In trial'
      }, {
        value: 'subscribed',
        label: 'Subscribed'
      }, {
        value: 'trialEnded',
        label: 'Trial ended'
      }],
      quillOptions: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline'],
              [{header: [1, 2, 3, false]}],
              [{'list': 'ordered'}, {'list': 'bullet'}],
              ['link', 'image'],
              ['clean']
            ]
          }
        }
      },
      email: {
        timing: null,
        subject: {he: null, en: null},
        sendTo: ['subscribed', 'inTrial'],
        attachments: [],
        content: {he: null, en: null}
      }
    }
  },
  async beforeMount() {

    if (this.$route.params.emailId) await this.getEmailById();
  },
  methods: {
    onEditorReady(editor) {
      editor.root.setAttribute('dir', 'rtl');
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      const fileType = file.type;


      const reader = new FileReader();
      reader.onload = (e) => {

        const attachment = {
          base64: e.target.result,
          mime: fileType,
          name: file.name,
          type: file.name.split(/\./g).pop()
        };

        this.email.attachments.push(attachment);
      };
      reader.readAsDataURL(file);
    },
    sendToAddRemove(tag) {

      if (tag) {
        const indexOf = this.email.sendTo.indexOf(tag);
        if (indexOf < 0) {

          this.email.sendTo.push(tag);
        } else {
          this.email.sendTo.splice(indexOf, 1);
        }
      }
    },
    initUploadModal() {

      this.$refs.file.click();
    },
    async getEmailById() {

      const emailId = this.$route.params.emailId;
      this.email = await NotificationService.getTimedEmailById(emailId);
    },
    async sendEmail(isTest = false) {

      if (isTest) this.email.isTest = true;

      if (!isTest) {

        let confirm = window.confirm('You are about to send an email to users. Continue?');
        if (!confirm) return false;
      }
      if (this.email.sendTo.length === 0 && !this.email.isTest) {

        alert('Select a "Send to" group.');
        return;
      }

      // if (!this.email.subject.he || this.email.subject.he.toString().trim().length === 0) {
      //
      //   alert('Write Hebrew subject');
      //   return;
      // }

      if (!this.email.subject.en || this.email.subject.en.toString().trim().length === 0) {

        alert('Write English subject');
        return;
      }

      // if (!this.email.content.he || this.email.content.he.toString().trim().length === 0) {
      //
      //   alert('Write Hebrew content');
      //   return;
      // }

      if (!this.email.content.en || this.email.content.en.toString().trim().length === 0) {

        alert('Write English content');
        return;
      }
      const {emailId} = await NotificationService.postNewEmail(this.email);

      if (emailId) {

        if (!this.email.isTest) {
          this.$refs.file.value = '';

          this.formKey++;
          this.email = {
            timing: null,
            subject: {he: null, en: null},
            sendTo: ['subscribed', 'inTrial'],
            attachments: [],
            content: {he: null, en: null}
          }
        }
      }

    }
  }
}
</script>

<style scoped lang="scss">

@import "../../../assets/style/controls";
@import "../../../assets/style/texteditor";

section {
  overflow-y: auto;
  overflow-x: hidden;
  height: 93.5vh;
}

#form {
  max-width: 660px;
  display: block;
  margin: 1rem auto;
}


.imagePreview {
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--notficationBorderColor);
  overflow: hidden;
  margin-bottom: .5rem;

  img {
    width: 100%;
  }
}

.fileRow {
  align-items: flex-end;
}

.attachment {
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 10px 0 10px 15px;
  border-bottom: 1px solid var(--notficationBorderColor);

  &:last-of-type {
    border-bottom: none;
  }

  i {
    cursor: pointer;
    color: var(--red);
    padding: 4px;
    border-radius: 8px;
    background: var(--opacityRed);
  }
}
</style>
