import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import 'remixicon/fonts/remixicon.css'

import PrimeVue from 'primevue/config';

window.BASE_API_URL = process.env.VUE_APP_API_BASE_URL;

import './services/http';


createApp(App).use(router).use(PrimeVue, {}).mount('#app')
