const headers = [{
    label: 'Symbol',
    sortValue: 'symbol'
}, {
    label: 'Status',
    sortValue: 'status'
},
//     {
//     label: 'Is public',
//     sortValue: 'isPublic'
// },
    {
        label: 'Date added',
        sortValue: 'dateAdded'
    }, {
        label: 'Last update',
        sortValue: 'lastUpdate'
    },
    {
        label: 'Price change',
        sortValue: 'priceChangeSinceCreation'
    },
    {
        label: 'Followups',
    }, {
        label: 'Actions',
        sortValue: null
    }];

export default headers;
