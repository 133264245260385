<template>
  <section>

    <PageToolbar :title="`Notifications`" :hasBack="isPostNotification">
      <MBPButton v-if="!isPostNotification" label="Post a new notification" class="action" @click="goToPostNotifications()"></MBPButton>
    </PageToolbar>

    <router-view/>
  </section>
</template>

<script>


import PageToolbar from "../components/ui/PageToolbar";
import MBPButton from "../components/ui/controls/MBPButton";
export default {
  name: "Notifications",
  components: {MBPButton, PageToolbar},
  data() {
    return {
      isPostNotification: false
    }
  },
  methods: {
    goToPostNotifications () {
      this.$router.push('/notifications/notification');
      this.$router.go(1);
    }
  }
}
</script>

<style scoped lang="scss">


</style>
