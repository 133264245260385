<template>
  <section>

    <div id="form">
      <h1>Post a notification</h1>
      <div class="field">
        <MBPTextarea label="Title" v-model="notification.title"/>
      </div>

      <div class="field row">
        <MBPRadio label="Stock news" label-id="symbol" name="type" v-model="notification.type" value="1"/>
        <MBPRadio label="Macro news" label-id="news" name="type" v-model="notification.type" value="0"/>
        <MBPCheckbox label-id="isBreaking" style="margin-left: auto; color: var(--red);" label="Breaking news"
                     name="isBreaking" v-model="notification.isBreaking" value="true"/>
        <MBPCheckbox label-id="isEmphasis" style="color: var(--lightYellow);" label="Breaking without push"
                     name="isEmpahsis" v-model="notification.isEmphasis" value="true"/>
      </div>

      <div class="field">
        <MBPInput label="Symbols (separated by space: AAPL NFLX TSLA)" v-model="notification.symbols"/>
      </div>

      <div class="field">
        Tags:
        <div style="height: 5px;"/>

        <div class="row wrap">
          <MBPCheckbox v-for="(tag) in tags" style="margin-bottom: 0" :key="tag.value" :values="this.notification.tags"
                       :value="tag.value" name="tags"
                       @change="tagAddRemove($event?.target?.value)" :label-id="tag.value" :label="tag.label"/>
        </div>
      </div>

      <div class="field">
        <MBPInput label="Link (leave empty if internal)" v-model="notification.link"/>
      </div>

      <div class="field">
        Image:
        <div style="height: 5px;"/>

        <div class="imagePreview" v-if="notification.image">
          <img alt="Image"
               :src="notification.image.includes('base64') ? notification.image : setImage(notification.image)"/>
        </div>

        <label for="file">
          <MBPButton label="Upload" class="action outline" @click="initUploadModal"/>
        </label>
        <input style="display: none;" id="file" type="file" @change="handleFileChange"
               accept="image/*,.pdf,.csv,.xls,.xlsx,.doc,.docx" ref="file"/>

        <MBPButton label="Remove image" v-if="notification.image" style="margin-left: 10px;" class="danger"
                   @click="notification.image = null;"/>
      </div>

      <div class="field">
        Timing:
        <div style="height: 5px;"/>
        <DatePicker showTime hourFormat="24" :minDate="new Date()" v-model="notification.timing" dateFormat="dd/mm/yy"/>
      </div>

      <div class="field">
        Content:
        <div style="height: 5px;"/>
        <quill-editor theme="snow" contentType="html" v-model:content="notification.content"/>
      </div>

      <div class="field">
        <MBPButton label="Post notification" class="action large" @click="postNotification"/>
      </div>

    </div>

  </section>
</template>

<script>


import NotificationService from '../../../services/notifications.service';
import MBPInput from "../../../components/ui/controls/MBPInput";
import MBPCheckbox from "../../../components/ui/controls/MBPCheckbox";
import MBPRadio from "../../../components/ui/controls/MBPRadio";

/** deps and libs **/
import {QuillEditor} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import MBPButton from "../../../components/ui/controls/MBPButton";

import DatePicker from 'primevue/datepicker';
import MBPTextarea from "../../../components/ui/controls/MBPTextarea";


export default {
  name: "PostNotification",
  components: {MBPTextarea, MBPButton, MBPRadio, MBPCheckbox, MBPInput, QuillEditor, DatePicker},
  data() {
    return {
      tags: [{
        value: 'signal',
        label: 'Signal'
      }, {
        value: 'trade',
        label: 'Trade'
      }, {
        value: 'taReview',
        label: 'Review'
      }, {
        value: 'earnings',
        label: 'Earnings'
      }, {
        value: 'price target',
        label: 'Price target (analyst)'
      }, {
        value: 'form4',
        label: 'Insider'
      }, {
        value: 'federal reserve',
        label: 'Federal reserve'
      }, {
        value: 'guidance',
        label: 'Guidance'
      }, {
        value: 'hedge funds',
        label: 'Hedge funds'
      }, {
        value: 'trading halt',
        label: 'Trading halt'
      }, {
        value: 'trading resumption',
        label: 'Trading resumption'
      }, {
        value: 'pr',
        label: 'Press release'
      }, {
        value: 'news',
        label: 'News'
      }, {
        value: 'system',
        label: 'System notification'
      }],
      symbol: null,
      notification: {
        timing: null,
        title: null,
        type: '1',
        symbols: null,
        isBreaking: false,
        isEmphasis: false,
        link: null,
        tags: [],
        image: null,
        content: null
      }
    }
  },
  async beforeMount() {

    if (this.$route.params.notificationId) await this.getNotificationById();
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];

      const fileType = file.type;


      const reader = new FileReader();
      reader.onload = (e) => {

        if (file && file.type.startsWith('image/')) {
          this.notification.image = e.target.result;
        } else {
          this.notification.file = e.target.result;
        }
      };
      reader.readAsDataURL(file);
    },
    tagAddRemove(tag) {

      if (tag) {
        const indexOf = this.notification.tags.indexOf(tag);
        if (indexOf < 0) {

          this.notification.tags.push(tag);
        } else {
          this.notification.tags.splice(indexOf, 1);
        }
      }
    },
    initUploadModal() {

      this.$refs.file.click();
    },
    setImage(image) {

      return process.env.VUE_APP_FILES_BASE_URL + `/notifications/images/${image}`
    },
    hasNoSymbols() {


      return !this.notification.symbols || this.notification.symbols.trim().length === 0
    },
    async getNotificationById() {

      const notificationId = this.$route.params.notificationId;
      this.notification = await NotificationService.getTimedNotificationById(notificationId);
    },
    async postNotification() {

      if (this.notification.type == 1 && this.hasNoSymbols()) {

        alert('This is a stock notifications, but you did not add any symbols.')
        return;
      }

      if (!this.notification.tags || this.notification.tags.length === 0) {

        alert('Please select at least ONE tag.')
        return;
      }


      const {notificationId} = await NotificationService.postNotification(this.notification);
      if (notificationId) {

        // this.$router.push('/notifications');
        // this.$router.go(1);

        this.$refs.file.value = '';

        this.notification = {
          timing: null,
          title: null,
          type: '1',
          symbols: null,
          isBreaking: false,
          isEmphasis: false,
          link: null,
          tags: [],
          image: null,
          content: null
        }
      }

    }
  }
}
</script>

<style scoped lang="scss">

@import "../../../assets/style/controls";

section {
  overflow-y: auto;
  overflow-x: hidden;
  height: 93.5vh;
}

#form {
  max-width: 660px;
  display: block;
  margin: 1rem auto;
}

:deep {

  .p-datepicker-panel {
    background: var(--bgColor) !important;
    border: 1px solid var(--notficationBorderColor) !important;
    border-radius: 8px;
    padding: 10px;
    box-sizing: content-box;
  }

  .p-datepicker-calendar {
    background: blue;
  }

  .ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover {
    background-color: var(--tableHoverColorOpacity);
  }

  .ql-toolbar.ql-snow {
    border-color: var(--notficationBorderColor) !important;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-color: var(--notficationBorderColor) !important;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .ql-snow .ql-picker-options {
    background: var(--bgColor);
    border-color: var(--notficationBorderColor) !important;
    border-radius: 8px;
  }

  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    border: none !important;
  }

  .ql-selected,
  .ql-active {
    color: var(--mbpGreen) !important;
    background-color: var(--mbpGreenOpacity) !important;

    .ql-stroke {
      stroke: var(--mbpGreen) !important;
    }
  }

  .ql-editor {
    font-size: 1rem;
    line-height: 24px;
    background: var(--tableHoverColorOpacity);
    min-height: 250px;

    p {
      margin-bottom: 1rem;
    }
  }

  li, ul {
    margin-bottom: .5rem;
  }

  .ql-snow a {
    color: var(--mbpGreen) !important;
  }

  .ql-snow .ql-picker {
    color: var(--textColor) !important;
  }

  .ql-snow .ql-stroke {
    stroke: var(--textColor);
  }

}

.imagePreview {
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--notficationBorderColor);
  overflow: hidden;
  margin-bottom: .5rem;

  img {
    width: 100%;
  }
}


</style>
