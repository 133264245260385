<template>

  <section :key="formKey">
    <BigLoader v-if="isLoading"/>
    <div style="padding: 1rem; max-width: 960px; margin: 0 auto" v-else>
      <div class="field">
        <MBPInput label="Symbol" :error-note="errors.noSymbol" autocomplete="false" type="text" label-id="symbol"
                  v-model="trade.symbol"/>
      </div>


      <!--    <div class="field">-->
      <!--      <MBPDropDown :error-note="errors.noDirection"-->
      <!--                   dropdownLabel="Select status"-->
      <!--                   :default-selected="trade.direction"-->
      <!--                   @select="trade.status = $event.value"-->
      <!--                   :options="directionOptions"/>-->
      <!--    </div>-->

      <div class="field row">
        <label>
          Trade status:
        <MBPDropDown :error-note="errors.noStatus"
                     dropdownLabel="Select status"
                     style="margin-top: 5px;"
                     :default-selected="trade.status"
                     @select="trade.status = $event.value"
                     :options="statusOptions"/>
        </label>

        <label>
          Direction:
        <MBPDropDown
                     dropdownLabel="Select status"
                     style="margin-top: 5px;"
                     :default-selected="trade.direction"
                     @select="trade.direction = $event.value"
                     :options="directionOptions"/>
        </label>
      </div>

      <div v-if="this.$route.params.tradeId">
        <div class="field">
          <h2>Followups</h2>

          <div class="followup" v-for="(followup, fIdx) in trade.followups"
               @click="showFollowup = fIdx; newFollowup = trade.followups[fIdx]; showNewFollowUp = true;">
            &bull; {{ followup.title.en }} ({{ formatDate(followup.dateAdded) }})
          </div>

          <div class="toggle" style="margin-top: 0;" @click="showNewFollowUp = true"
               v-if="this.$route.params.tradeId && !this.showNewFollowUp">
            Add new follow up
          </div>

          <div class="toggle" style="margin-top: 0;" @click="showNewFollowUp = false"
               v-if="this.$route.params.tradeId && this.showNewFollowUp">
            Hide new follow up
          </div>

          <div v-if="showNewFollowUp">

            <div class="modal">
              <h3>New followup</h3>

              <div class="field">
                <MBPTextarea label="English subject (keep it short)"
                             placeholder="For example: AAPL is nearing 52 weeks high..."
                             v-model="newFollowup.title.en"/>
              </div>

              <div class="field">
                English content:
                <div style="height: 5px;"/>
                <quill-editor theme="snow" :options="quillOptions" contentType="html"
                              v-model:content="newFollowup.content.en"/>
              </div>

              <MBPButton class="action" @click="translate(true, null)" label="Translate to Hebrew"/>

              <div class="field">
                <MBPTextarea dir="rtl" label="Hebrew subject (keep it short)" v-model="newFollowup.title.he"/>
              </div>
              <div class="field">
                Hebrew content:
                <div style="height: 5px;"/>
                <quill-editor theme="snow" :options="quillOptions" contentType="html" @ready="onEditorReady"
                              v-model:content="newFollowup.content.he"/>
              </div>
              <MBPButton style="margin: 2rem auto; display: block;" class="action large" @click="addFollowup"
                         :label="showFollowup === null ? 'Add followup' : 'Update followup'"/>
              <MBPButton style="margin: 2rem auto; display: block;" class="action large" @click="closeFollowup"
                         label="Close"/>
            </div>
          </div>


        </div>
      </div>

      <h2 v-if="this.$route.params.tradeId">Original trade</h2>
      <div class="toggle" @click="showOriginal = true" v-if="this.$route.params.tradeId && !this.showOriginal">
        See original trade
      </div>
      <div class="toggle" @click="showOriginal = false" v-if="this.$route.params.tradeId && this.showOriginal">
        Hide original trade
      </div>

      <div v-if="(this.$route.params.tradeId && this.showOriginal) || !this.$route.params.tradeId">

        <div :class="{modal: this.$route.params.tradeId && this.showOriginal}">
          <div class="field">
            <MBPTextarea label="English subject (keep it short)"
                         placeholder="For example: AAPL is nearing 52 weeks high..."
                         v-model="trade.title.en"/>
          </div>

          <div class="field">
            English content:
            <div style="height: 5px;"/>
            <quill-editor theme="snow" :options="quillOptions" contentType="html" v-model:content="trade.content.en"/>
          </div>

          <MBPButton class="action" @click="translate" label="Translate to Hebrew"/>

          <div class="field">
            <MBPTextarea dir="rtl" label="Hebrew subject (keep it short)" v-model="trade.title.he"/>
          </div>
          <div class="field">
            Hebrew content:
            <div style="height: 5px;"/>
            <quill-editor theme="snow" :options="quillOptions" contentType="html" @ready="onEditorReady"
                          v-model:content="trade.content.he"/>
          </div>

          <MBPButton style="margin: 2rem auto; display: block;" class="action large" @click="showOriginal = false"
                     label="Close"/>
        </div>
      </div>


      <!--    <MBPToggle off-text="No" on-text="Yes" label="Is public" :state="trade.isPublic"-->
      <!--               @stateChanged="trade.isPublic = !trade.isPublic"/>-->
      <MBPButton style="margin: 2rem auto; display: block;" class="action large" @click="saveTrade" label="Save trade"/>

    </div>
  </section>

</template>

<script>
import MBPInput from "../../../components/ui/controls/MBPInput";
import MBPDropDown from "../../../components/ui/controls/MBPDropDown";
import MBPTextarea from "../../../components/ui/controls/MBPTextarea";
import MBPButton from "../../../components/ui/controls/MBPButton";
import {QuillEditor} from '@vueup/vue-quill'
// Services
import TradeService from '../../../services/trades.service';
import MBPToggle from "../../../components/ui/controls/MBPToggle";
import BigLoader from "../../../components/ui/BigLoader";
import {DateTime} from "luxon";

export default {
  name: "TradeDetails",
  components: {BigLoader, MBPToggle, MBPButton, MBPTextarea, MBPDropDown, MBPInput, QuillEditor},
  data() {
    return {
      showFollowup: null,
      showNewFollowUp: false,
      showOriginal: false,
      directionOptions: [{
        label: 'None',
        value: null
      },{
        label: 'Long',
        value: 'long'
      }, {
        label: 'Short',
        value: 'short'
      }],
      direction: [{
        label: 'None',
        value: null,
      }, {
        label: 'Long',
        value: 'long'
      }, {
        label: 'Short',
        value: 'short'
      }],
      type: [{
        label: 'Trade',
        value: 'trade'
      }, {
        label: 'Signal',
        value: 'signal'
      }, {
        label: 'Review',
        value: 'review'
      }],
      statusOptions: [{
        label: 'Pattern found',
        value: 'patternFound'
      }, {
        label: 'Possible breakout',
        value: 'possibleBreakout'
      }, {
        label: 'Failed',
        value: 'failed'
      }, {
        label: 'Closed',
        value: 'closed'
      }],
      quillOptions: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline'],
              [{header: [1, 2, 3, false]}],
              [{'list': 'ordered'}, {'list': 'bullet'}],
              ['link', 'image'],
              ['clean']
            ]
          }
        }
      },
      isLoading: true,
      hasError: false,
      errors: {
        noSymbol: null,
        noStatus: null,
        noDirection: null,
        noHeContent: null,
        noEnContent: null
      },
      formKey: 0,
      newFollowup: {
        title: {
          he: null,
          en: null
        },
        content: {
          he: null,
          en: null
        }
      },
      trade: {
        isPublic: true,
        symbol: null,
        status: null,
        dateAdded: null,
        lastUpdate: null,
        direction: null,
        title: {
          he: null,
          en: null
        },
        content: {
          he: null,
          en: null
        },
        followups: []
      }
    }
  },
  async beforeMount() {

    const tradeId = this.$route.params.tradeId;

    if (tradeId) {

      this.trade = await TradeService.getTrade(tradeId);
    }
    this.isLoading = false;
  },
  methods: {
    async addFollowup() {

      if (this.showFollowup !== null) {

        this.trade.followups[this.showFollowup].title = this.newFollowup.title;
        this.trade.followups[this.showFollowup].content = this.newFollowup.content;
        await this.updateTrade(this.showFollowup);
        this.showNewFollowUp = false;
        this.showFollowup = null;

      } else {

        const index = this.trade.followups.length;
        this.trade.followups.unshift(Object.assign(this.newFollowup, {followupIndex: index, dateAdded: new Date()}));
        await this.updateTrade(index, true);
        this.showNewFollowUp = false;
      }

      this.newFollowup = {
        title: {
          he: null,
          en: null
        },
        content: {
          he: null,
          en: null
        }
      }
    },
    async translate(isFollowup = false, followUpIndex = null) {

      let titleText = null;
      let contentText = null;

      if (!isFollowup) {

        titleText = this.trade.title.en;
        contentText = this.trade.content.en;
      } else {
        if (followUpIndex === null) {

          titleText = this.newFollowup.title.en;
          contentText = this.newFollowup.content.en;
        } else {

          titleText = this.trade.followups[followUpIndex].title.en;
          contentText = this.trade.followups[followUpIndex].content.en;
        }
      }

      const {title, content} = await TradeService.translate({
        title: titleText,
        content: contentText
      });

      if (!isFollowup) {
        this.trade.title.he = title;
        this.trade.content.he = content;
      } else {
        if (followUpIndex === null) {
          this.newFollowup.title.he = title;
          this.newFollowup.content.he = content;
        } else {
          this.trade.followups[followUpIndex].title.he = title;
          this.trade.followups[followUpIndex].content.he = content;
        }
      }
    },
    closeFollowup() {
      this.showNewFollowUp = false;

      if (this.showFollowup !== null) {
        this.showFollowup = null;
        this.newFollowup = {
          title: {
            he: null,
            en: null
          },
          content: {
            he: null,
            en: null
          }
        }
      }
    },
    formatDate(date) {

      return !date.toString().includes('GMT') ? DateTime.fromISO(date).toFormat('DD') : DateTime.now().toFormat('DD');
    },
    onEditorReady(editor) {
      editor.root.setAttribute('dir', 'rtl');
    },
    clearErrors() {
      this.hasError = false;
      for (let k in this.errors) this.errors[k] = null;
    },
    isFieldEmpty(field) {
      return (field === null ||
          field === undefined ||
          field.toString().trim().length === 0);
    },
    async updateTrade(followupIndex = null, isNewFollowup = false) {

      try {

        await TradeService.updateTrade({
          trade: Object.assign(this.trade, isNewFollowup ? {isNewFollowup: true} : {}),
          tradeId: this.$route.params.tradeId,
          followupIndex,
          isNewFollowup
        });
        alert('Trade was updated.');
      } catch (e) {
        alert(e.message);
      }
    },
    async createTrade() {

      try {

        await TradeService.createTrade(this.trade);
        const confirm = window.confirm('New trade was created. Create another one?');
        if (confirm) {


          this.trade = {
            isPublic: true,
            symbol: null,
            status: null,
            dateAdded: null,
            lastUpdate: null,
            direction: null,
            title: {
              he: null,
              en: null
            },
            content: {
              he: null,
              en: null
            },
            followups: []
          }

          this.formKey++;
        } else {
          this.$router.push('/trades');
          this.$router.go(1);
        }
      } catch (e) {
        alert(e.message);
      }
    },
    async saveTrade() {

      this.clearErrors();
      if (this.isFieldEmpty(this.trade.symbol)) {
        this.errors.noSymbol = 'Field cannot be empty';
        this.hasError = true;
      }


      if (this.isFieldEmpty(this.trade.status)) {
        this.errors.noStatus = 'Field cannot be empty';
        this.hasError = true;
      }


      if (!this.hasError) {

        if (this.$route.params.tradeId) {

          await this.updateTrade();

        } else {
          await this.createTrade();
        }
      } else {
        alert('Errors. Please check form.')
      }

    }
  }
}
</script>

<style scoped lang="scss">

@import "../../../assets/style/controls";
@import "../../../assets/style/texteditor";

section {
  overflow-y: auto;
  overflow-x: hidden;
  height: 92.3vh;
}

h2 {
  border-top: 1px solid var(--notficationBorderColor);
  padding-top: 1rem;
}

.textWrapper {
  padding: 0;
  box-sizing: border-box;
}

.borderless {
  border: none;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  background: transparent;
  color: white;
  resize: none;

  &:focus {
    outline: none;
    border: none;
  }
}

.toggle {
  cursor: pointer;
  border-radius: 8px;
  padding: 10px;
  font-weight: bold;
  background: var(--lightYellowOpacity);
  color: var(--lightYellow);
  margin: 0 0 1rem;
}

.followup {
  padding: 10px;
  border-radius: 8px;
  background: var(--tableHoverColorOpacity);
  margin-bottom: 10px;
}

.modal {
  position: fixed;
  border-radius: 12px;
  left: 50%;
  top: 1rem;
  height: calc(100vh - 2rem);
  transform: translateX(-50%);
  background: var(--bgColor);
  z-index: 9999;
  padding: 1rem;
  width: 960px;
  box-shadow: 0 0 500px 500px rgba(0, 0, 0, 0.8);
  overflow-y: scroll;
}

</style>
