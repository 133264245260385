
const PATH = '/notifications';

export default {
    async postNotification(notification) {

        return axios.post(`${PATH}`, notification);
    },
    async getTimedNotificationById (id) {

        return axios.get(`${PATH}/timed/${id}`);
    },
    async getTimedNotificationsList () {

        return axios.get(`${PATH}/timed`);
    },
    async postNewEmail(email) {

        return axios.post(`${PATH}/email`, email);
    },
    async getTimedEmailById (id) {

        return axios.get(`${PATH}/email/timed/${id}`);
    },
    async getTimedEmailsList () {

        return axios.get(`${PATH}/email/timed`);
    }
}
