<template>
  <label :for="labelId">
    <input type="radio" :name="name" :id="labelId" v-bind="$attrs" :checked="modelValue === value" :value="value" @change="$emit('update:modelValue', $event.target.value)">
    {{label}}
  </label>
</template>

<script>
export default {
  name: "MBPRadio",
  props: ['labelId', 'label', 'value', 'name', 'values', 'modelValue', 'defaultChecked'],
  computed: {

  },
}
</script>

<style scoped lang="scss">
label {
  cursor: pointer;
}


input, label {
  user-select: none;
}
</style>
