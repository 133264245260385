function findPercentileValue(arr, percentile) {
    if (percentile < 0 || percentile > 100) {
        throw new Error('Percentile must be between 0 and 100.');
    }

    // Sort the array in ascending order
    arr.sort((a, b) => a - b);

    // Calculate the index for the given percentile
    let index = Math.ceil((percentile / 100) * arr.length) - 1;

    // Return the value at that index
    return arr[index];
}

export default (candles, thresholdValue = null) => {

    let highs = candles.filter(c => c.high !== null).map(c => c.high);
    let lows = candles.filter(c => c.low !== null).map(c => c.low);
    let maxHigh = Math.max(...highs);
    let maxLow = Math.min(...lows);
    const binsSize = (maxHigh - maxLow) / 25;

    let highestHigh = candles.find(high => high.high === maxHigh);
    const highestHighDate = new Date(highestHigh.date).getTime();
    let lowestLow = candles[0];

    for (let i = candles.length - 1; i > -1; i--) {

        if (lowestLow.low > candles[i].low && candles[i - 1].low > candles[i].low) {
            lowestLow = candles[i];
            break;
        } else {
            lowestLow = candles[i];
        }
    }

    let bins = {};

    for (let i = 0; i < 25; i++) {
        let bottomLimit = maxLow + (i * binsSize);
        let topLimit = maxLow + (i * binsSize) + binsSize;
        bins[i] = {s: {bottomLimit, topLimit, candles: [], avg: (bottomLimit + topLimit) / 2}, r: {bottomLimit, topLimit, candles: [], avg: (bottomLimit + topLimit) / 2}}
    }

    candles.reverse().forEach(c => {

        for (let k in bins) {

            const isLowInBound = c.low >= bins[k].s.bottomLimit && c.low <= bins[k].s.topLimit;
            const isHighInBound = c.high >= bins[k].r.bottomLimit && c.high <= bins[k].r.topLimit;

            if (isLowInBound) {
                bins[k].s.candles.push(c)
            }

            if (isHighInBound) {
                bins[k].r.candles.push(c)
            }
        }
    });

    let srLines = Object.values(bins).map(bin => {

        bin.r.strength = bin.r.candles.length;
        bin.s.strength = bin.s.candles.length;

        bin.r.avg = bin.r.candles.reduce((final, current) => {

            final += current.high;
            return final;
        }, 0) / bin.r.strength;

        bin.s.avg = bin.s.candles.reduce((final, current) => {

            final += current.low;
            return final;
        }, 0) / bin.s.strength;

        return bin;
    }).reduce((final, current) => {

        final.support.push(current.s);
        final.resistance.push(current.r);

        return final;
    }, {support: [], resistance: []});


    // const highSupportDiff = bins[0] ? ((maxHigh - bins[0].avg) / bins[0].avg) * 100 : null;
    // const threshold = thresholdValue === null ? null : findPercentileValue(volumeProfileStrength, thresholdValue);
    const volumeProfileStrengthResistance = srLines.resistance.map(bin => bin.strength);
    const volumeProfileStrengthSupport = srLines.support.map(bin => bin.strength);
    const thresholdResistance = findPercentileValue(volumeProfileStrengthResistance, 95);
    const thresholdSupport = findPercentileValue(volumeProfileStrengthSupport, 95);

    srLines.support.filter(line => line.strength >= thresholdSupport);
    srLines.resistance.filter(line => line.strength >= thresholdResistance);

    return srLines;
}
