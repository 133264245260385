const headers = [{
    label: 'Title',
}, {
    label: 'Symbols',
    sortValue: 'symbols'
}, {
    label: 'Timing',
    sortValue: 'timing'
}, {
    label: 'Actions',
    sortValue: null
}];

export default headers;
