<template>
  <main class="chartArea" style="overflow: hidden">
    <div>
      <MBPInput v-model="symbol" @enter="getChartBySymbol()"/>
      <CandleChart :key="chartKey" :symbol="symbol"/>
    </div>
  </main>
</template>

<script>
import ChartService from '../services/chart.service';
import MBPInput from "../components/ui/controls/MBPInput";
import CandleChart from "../components/charts/CandleChart";



export default {
  name: "ChartView",
  components: {CandleChart, MBPInput},
  data() {
    return {
      chartKey: 0,
      chart: null,
      chartOptions: {},
      symbol: 'LNW'
    }
  },
  async beforeMount() {


    await this.getChartBySymbol();
  },
  methods: {
    async getChartBySymbol() {


      this.chart = await ChartService.getChartBySymbol(this.symbol);
      this.chartKey++;

    }
  }
}
</script>

<style scoped lang="scss">

.chartArea {
  flex-direction: column;
}
</style>
