<template>
  <section>

    <DataGridTable :is-compact="true"
                   :key="tableKey"
                   :data="emails"
                   :hovered-columns="true"
                   :loaded="wasLoaded"
                   :isLoading="isLoading"
                   :default-sort="defaultSort"
                   :heightElements="['topBar','title']"
                   :default-sort-direction="defaultSortDirection"
                   :fixed-col="0">
      <template #thead="{thClick, sortKey, sortDirection, xAxisScrolled}">
        <HeadCell v-for="(th, thIndex) in headers"
                  :sortKey="sortKey"
                  :sortDirection="sortDirection"
                  :sortValue="th.sortValue"
                  @sort="thClick(th.sortValue)">
          {{ th.label }}
        </HeadCell>
      </template>

      <template #tbody="{rows}">
        <tr v-for="(row, rowIndex) in rows" :key="`r-${rowIndex}`">
          <td @click="goToEmailById(row._id)">{{ row.subject.en }}</td>
          <td @click="goToEmailById(row._id)">{{row.sendTo.join(', ')}}</td>
          <td @click="goToEmailById(row._id)">{{ formatDate(row.timing) }}</td>
          <td @click="goToEmailById(row._id)">{{ row.wasSent ? 'Yes' : 'No' }}</td>
          <td>
            <div class="actionsTd">
              <i class="ri-delete-bin-line" @click="showDeleteTradeModal = row._id"/>
            </div>
          </td>
        </tr>
      </template>

    </DataGridTable>

  </section>
</template>

<script>
import DataGridTable from "../../../components/dataGrid/DataGridTable";
import {DateTime} from "luxon";
import HeadCell from "../../../components/dataGrid/HeadCell";

import EmailsGridHeaders from "../../../data/EmailsGridHeaders";

import NotificationsService from '../../../services/notifications.service';

export default {
  name: "EmailsTable",
  components: {HeadCell, DataGridTable},
  data () {
    return {
      emails: [],
      isLoading: false,
      wasLoaded: false,
      defaultSort: 'timing',
      tableKey: 1,
      defaultSortDirection: 1,
      headers: EmailsGridHeaders
    }
  },
  beforeMount() {

    this.getTimedEmailsList().then().catch();
  },
  methods: {
    async getTimedEmailsList () {

      this.emails = await NotificationsService.getTimedEmailsList();
    },
    formatDate(date) {

      return DateTime.fromISO(date).toFormat('DD HH:mm')
    },
    goToEmailById (id) {

      this.$router.push(`/emails/email/${id}`);
      this.$router.go(1);
    },
  }
}
</script>

<style scoped lang="scss">

</style>
