<template>

  <div class="toolbar">
    <h1><i class="ri-arrow-left-line" style="cursor: pointer" v-if="hasBack" @click="goBack()"/> {{title}}</h1>

    <div class="tools">
      <slot/>
    </div>
  </div>


</template>

<script>
export default {
  name: "PageToolbar",
  props: ['title', 'hasBack'],
  methods: {
    goBack () {
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped lang="scss">

h1 {
  margin: 0;
  padding: 0;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 1rem;
  border-bottom: 1px solid #000;

  .tools {
    display: flex;
    align-items: center;
    gap: .5rem;
  }
}
</style>
