<template>
  <div class="table" ref="table" @scroll="scrolled">
    <table cellspacing="0" cellpadding="0" :class="{isCompact}"
           @mouseleave="hoveredColumn = null; removeHoveredColumn()">
      <thead>
      <tr>
        <slot name="thead"
              :xAxisScrolled="xAxisScrolled"
              :sortKey="sortKey"
              :sortDirection="sortDirection"
              :thClick="thClick"/>
      </tr>
      </thead>
      <tbody>
      <slot name="tbody"
            :xAxisScrolled="xAxisScrolled"
            :rows="rows"
      />
      </tbody>
    </table>
    <!--    <div class="tableGradient" ref="gradient" :style="setGradientWidth()"/>-->
  </div>
</template>

<script>

import Tabs from "../ui/Tabs";
import {useResizeObserver} from '@vueuse/core'
import {getCurrentInstance} from "vue";

export default {
  name: "DataGridTable",
  components: {Tabs},
  props: ['data', 'defaultSort', 'dontSort', 'defaultSortDirection', 'fixedCol', 'isLoading', 'isCompact', 'heightElements', 'loaded', 'hoveredColumns'],
  data() {
    return {
      hoveredColumn: null,
      xAxisScrolled: false,
      sortKey: this.defaultSort,
      sortDirection: this.defaultSortDirection
    }
  },
  computed: {
    rows() {

      if (!this.data || this.data.length === 0) return [];
      return (!this.dontSort) ? this.sortByKey(this.data) : this.data;
    }
  },
  watch: {
    data(currentVal, oldVal) {
      this.setHeight();

      if (currentVal.length > 0 && this.hoveredColumns) {
        this.$nextTick(() => {

          this.setHoverColumn()
        });
      }
    }
  },
  methods: {
    setHoverColumn() {

      if (this.$refs.table) {
        this.$refs.table.querySelectorAll('th')
            .forEach((th, index) => {
              th.removeEventListener('mouseover', this.columnHover)
              th.addEventListener('mouseover', ev => this.columnHover(index))
            });

        this.$refs.table.querySelectorAll('tr')
            .forEach(tr => {

              tr.querySelectorAll('td')
                  .forEach((td, index) => {
                    td.removeEventListener('mouseover', this.columnHover)
                    td.addEventListener('mouseover', ev => this.columnHover(index))
                  });
            })
      }
    },
    setHeight() {
      if (this.data?.length > 0 && this.$refs.table) {

        this.$nextTick(() => {


          let heightElements = this.heightElements || [];
          if (heightElements.length === 0) heightElements = [this.$refs.table.parentElement];

          if (heightElements && heightElements.length > 0) {

            const tableInnerHeight = this.$refs.table.clientHeight;
            const mainContainerHeight = document.getElementById('macroNewsFeed')?.clientHeight || 0;
            let totalHeightToSubtract = heightElements.reduce((final, current) => {

              final += document.getElementById(current)?.clientHeight || 0;
              return final;
            }, 0);


            let tableHeight = mainContainerHeight - totalHeightToSubtract;

            // if (tableHeight > tableInnerHeight) tableHeight = tableInnerHeight - 40.9;
            this.$refs.table.style.height = tableHeight - 12.9 + 'px';
          }
        })
      }
    },
    setGradientWidth() {
      if (this.data?.length > 0) {

        this.$nextTick(() => {

          const width = this.$refs.table.clientWidth;
          const left = this.$refs.table.offsetLeft;
          const scrollWidth = this.$refs.table.scrollWidth;
          // if (scrollWidth > width) this.$refs.gradient.style.bottom = '10px';
          //
          // this.$refs.gradient.style.width = `${width}px`;
          // this.$refs.gradient.style.left = `${left}px`;
        })
      }
    },
    scrolled(ev) {
      const scrollLeftPosition = ev.target.scrollLeft;
      const scrollTopPosition = ev.target.scrollTop;
      const scrollHeight = ev.target.scrollHeight;
      const clientHeight = ev.target.clientHeight;

      const scrollPercentage = (scrollTopPosition / (scrollHeight - clientHeight));
      this.$emit('scrollTopProgress', scrollPercentage);

      // this.$refs.gradient.style.opacity = 1 - (scrollPercentage);

      // this.$refs.gradient.style.display = scrollTopPosition + clientHeight >= scrollHeight ? 'none' : 'unset';

      this.xAxisScrolled = Math.abs(scrollLeftPosition) > 1;

      const rows = this.$refs?.table ? this.$refs.table.querySelectorAll('tr') : [];

      for (let row of rows) {
        const tds = row.querySelectorAll('td');
        const ths = row.querySelectorAll('th');

        const cells = ths.length > 0 ? ths : tds;

        if (cells.length > 0) {
          for (let i = 0; i < this.fixedCol + 1; i++) {
            cells[i].classList[this.xAxisScrolled ? 'add' : 'remove']('stickyCell');
            if (this.xAxisScrolled && i > 0) {

              let previousCellWidth = cells[i - 1].offsetWidth;
              cells[i].style.right = previousCellWidth - 3 + 'px';
            }
          }

          cells[this.fixedCol].classList[this.xAxisScrolled ? 'add' : 'remove']('xAxisScrolled')
        }
      }
    },
    removeHoveredColumn() {

      if (this.$refs.table) {
        this.$refs.table.querySelectorAll(`.hoveredColumn`)
            .forEach((cell) => {
              cell.classList.remove('hoveredColumn');
            });
      }
    },
    columnHover(index) {

      if (this.$refs.table) {
        this.hoveredColumn = index + 1;
        this.removeHoveredColumn();
        this.$refs.table.querySelectorAll(`th:nth-of-type(${this.hoveredColumn}), td:nth-of-type(${this.hoveredColumn})`)
            .forEach((cell) => {
              cell.classList.add('hoveredColumn');
            });
      }
    },
    sortByKey(array) {
      return array.sort((a, b) => {

        if (!this.sortKey) return array;

        let sortKey = null;

        if (this.sortKey instanceof Function) {
          sortKey = this.sortKey();
        } else {
          sortKey = this.sortKey;
        }
        const keys = sortKey.split('.');
        let aValue = a;
        let bValue = b;

        for (const k of keys) {
          aValue = aValue[k];
          bValue = bValue[k];
        }

        const comparison = aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
        return comparison * this.sortDirection;
      });
    },
    thClick(key) {

      if (key === this.sortKey) {
        this.sortDirection *= -1;
      } else {
        this.sortKey = key;
        this.sortDirection = 1;
      }
    },
    handleResize() {
      this.setHeight()
    }
  },
  created() {
    this.setHeight()
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/vars";

.table {
  position: relative;
  overflow: auto;
  white-space: nowrap;
  transition: $transition;

  &.fadeOutTable {
    opacity: .2;
    filter: blur(1px);
  }
}

table {
  width: 100%;
}

table :slotted(th) {
  text-align: left;
  background: var(--bgColor);
  width: fit-content;
  font-weight: 600;
  border-right: 1px solid var(--notficationBorderColor);
}

table[dir="rtl"] :slotted(th) {
  text-align: right;
  border-right: unset;
  border-left: 1px solid var(--notficationBorderColor);
}

table :slotted(tr) {
  background: var(--bgColor);

  &:hover {
    background: var(--tableHoverColor);

    td {
      background: var(--tableHoverColor);
    }
  }
}

table :slotted(td) {
  padding: 5px 10px !important;
  border-bottom: 1px solid var(--notficationBorderColor);
  width: auto;
  font-weight: 500;
  border-right: 1px solid var(--notficationBorderColor);
}

table[dir="rtl"] :slotted(td) {
  border-right: unset;
  border-left: 1px solid var(--notficationBorderColor);
}

:slotted(td.center) {
  text-align: center;
}

table.isCompact :slotted(td),
table.isCompact :slotted(th) {
  font-size: 15px;
}

table :slotted(.bidi) {
  unicode-bidi: plaintext;
}

:slotted(td.trendTd) {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

:slotted(.textOverflow) {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

:slotted(td.trendTd.green) {
  color: var(--green);

  &:before {
    background: var(--opacityGreen);
  }
}

:slotted(td.trendTd.red) {
  color: var(--red);

  &:before {
    background: var(--opacityRed);
  }
}

:slotted(.xAxisScrolled) {
  box-shadow: 3px 0 2px 0 rgba(0, 0, 0, 0.05);
  border-right: 1px solid var(--notficationBorderColor);
}


table[dir="rtl"] :slotted(.xAxisScrolled) {
  border-right: unset;
  border-left: 1px solid var(--notficationBorderColor);
  box-shadow: -3px 0 2px 0 rgba(0, 0, 0, 0.05);
}

:slotted(.stickyCell) {
  background: inherit;
  position: sticky;
  left: 0;
  border-right: 1px solid var(--tableBorderColor);
  z-index: 1;
}

:slotted(.hoveredColumn) {
  background: var(--tableHoverColor) !important;
}

:slotted(.cellHighlight) {
  background: var(--opacityPurple) !important;
  color: var(--purple) !important;
}

:slotted(.symbolTd) {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;

  a {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

table[dir="rtl"] :slotted(.stickyCell) {
  border-right: unset;
  border-left: 1px solid var(--tableBorderColor);
  left: unset;
  right: 0;
}

thead {
  position: sticky;
  top: 0;
  z-index: 10;
  background: var(--bgColor);
}

.tableGradient {
  height: 50px;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
  background-image: linear-gradient(to bottom, rgba(255, 0, 0, 0), var(--bgColor));
  position: fixed;
}

</style>
