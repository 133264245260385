<template>
  <label :for="labelId">
    <span v-if="label">{{ label }}</span>
    <textarea ref="textarea" :id="labelId" :disabled="disabled" :class="{disabled}"
              @input="$emit('update:modelValue', $event.target.value); adjustHeight()"
              :style="{ height: `${textareaHeight}px` }" v-bind="$attrs" :placeholder="placeholder">{{modelValue}}</textarea>

    <small class="note" v-if="note">{{ note }}</small>
    <small class="error" v-if="errorNote !== null && errorNote !== undefined">{{ errorNote }}</small>
  </label>
</template>

<script>
export default {
  name: "MBPTextarea",
  props: ['label', 'disabled', 'modelValue', 'labelId', 'errorNote', 'note', 'hasError', 'placeholder'],
  data() {
    return {
      textareaHeight: 'auto'
    }
  },
  methods: {
    adjustHeight() {
      this.$nextTick(() => {
        const textarea = this.$refs.textarea;
        // textarea.style.height = 'auto';
        const newHeight = Math.min(textarea.scrollHeight, 350);
        this.textareaHeight = newHeight;
      });
    }
  }
}
</script>

<style scoped lang="scss">

@import "../../../assets/style/controls";

label {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

textarea {
  @extend .inputBase;
  cursor: text;
  -webkit-appearance: none;
  border: 2px solid var(--notficationBorderColor);
  text-align: unset;
  width: 100%;
  box-sizing: border-box;
  color: var(--textColor);
  font-family: sans-serif;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;

  &:focus {
    outline: none;
    border-color: var(--darkerBorder);
  }

  &.disabled {
    cursor: not-allowed;
    opacity: .8;
  }
}

small {

  padding: 2px 4px;
  border-radius: 4px;
  width: fit-content;

  &.error {
    color: var(--red);
    background: var(--opacityRed);
  }

  &.note {
    font-weight: 600;
    color: var(--lightYellow);
    background: var(--lightYellowOpacity);
  }
}


</style>
