<template>

  <div class="toggleSwitch" :class="{small}" @click="$emit('stateChanged')">
    <label style="cursor: pointer">
      {{label}}
    </label>

    <label style="cursor: pointer" v-if="onText">
      {{onText}}
    </label>

    <div class="toggleWrapper">
      <div class="toggleSwitch"  :class="{on: state, off: !state, twoStates: offText !== null && offText !== undefined}">
        {{onoff && !state ? offLabel : onoff && state ? onLabel : ''}}
      </div>
    </div>

    <label style="cursor: pointer" v-if="offText">
      {{offText}}
    </label>
  </div>
</template>

<script>
export default {
  name: "MBPToggle",
  props: ['state', 'label', 'offText', 'small', 'onText', 'onoff'],
  emits: ['stateChanged'],
  computed: {

  }
}
</script>

<style scoped lang="scss">

.toggleSwitch {
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer !important;
  gap: 10px;


  label {
    flex: 1 0 auto;
    margin-bottom: 0 !important;
  }
  .toggleWrapper {
    width: 35px;
    height: 20px;
    flex: 1 0 auto;
    background: #efefef;
    border-radius: 50px;
    position: relative;
    .toggleSwitch {
      user-select: none;
      font-size: 11px;
      position: absolute;
      top: 50%;
      left: 2px;
      transform: translateY(-50%);
      border-radius: 8px;
      width: 26px;
      justify-content: center;
      color: var(--bgColor);
      font-weight: 600;
      text-shadow: 0.1px 0.1px 0 var(--bgColor);
      height: 16px;
      background: var(--highlightColor);
      box-shadow: 0 0 10px 0 var(--highlightColorOpacity);
      transition: all .2s ease-in;

      &[dir="rtl"] {
        left: unset;
        right: 2px;
      }

      &.off {
        background: var(--red);
        box-shadow: 0 0 5px 0 var(--opacityRed);

        left: calc(100% - 28px);
        &[dir="rtl"] {
          left: unset;
          right: calc(100% - 28px);
        }

        &.twoStates {
          background: var(--bgColor);
        }
      }
    }
  }
}

.small {
  font-size: 14px;
  font-weight: 500;
}
</style>
