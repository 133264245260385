<template>

  <Modal>
    <slot name="title" v-if="$slots.title"></slot>
    <slot name="content" v-if="$slots.content"></slot>
    <slot name="actions" class="actions" v-if="$slots.actions">
    </slot>
  </Modal>
</template>

<script>
import Modal from "./Modal";
import MBPButton from "./controls/MBPButton";

export default {
  name: "ModalPrompt",
  components: {MBPButton, Modal},
  mounted() {
  }
}
</script>

<style scoped lang="scss">

:deep.actions {
  display: flex;
  gap: 1rem;
}

</style>
