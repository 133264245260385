class ZigZag {
    constructor(data, deviation = 5) {

        data = data.filter(point => point.high !== null && point.low !== null);
        this.data = data;
        this.deviation = deviation / 100;
        this.zigzag = [];
        this.peaks = [];
        this.troughs = [];
    }

    calculate() {
        let trend = 0;
        let lastPeak = {value: -Infinity, index: -1};
        let lastTrough = {value: Infinity, index: -1};

        for (let i = 0; i < this.data.length; i++) {
            const high = this.data[i].high;
            const low = this.data[i].low;
            const date = this.data[i].date;

            if (trend >= 0) {
                if (high > lastPeak.value) {
                    lastPeak = {value: high, index: i, date, type: 'high'};
                } else if (low < lastPeak.value * (1 - this.deviation)) {
                    this.zigzag.push(lastPeak);
                    this.peaks.push(lastPeak);
                    lastTrough = {value: low, index: i, date, type: 'low'};
                    trend = -1;
                }
            } else {
                if (low < lastTrough.value) {
                    lastTrough = {value: low, index: i, date, type: 'low'};
                } else if (high > lastTrough.value * (1 + this.deviation)) {
                    this.zigzag.push(lastTrough);
                    this.troughs.push(lastTrough);
                    lastPeak = {value: high, index: i, date, type: 'high'};
                    trend = 1;
                }
            }
        }

        // Add the last point
        if (trend >= 0) {
            this.zigzag.push(lastPeak);
            this.peaks.push(lastPeak);
        } else {
            this.zigzag.push(lastTrough);
            this.troughs.push(lastTrough);
        }

        return {
            zigzag: this.zigzag,
            peaks: this.peaks,
            troughs: this.troughs
        };
    }
}

export default (candles, deviation = 5) => {

    const zz = new ZigZag(candles, deviation);
    return zz.calculate();
}
