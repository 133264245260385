<template>
  <section>

    <PageToolbar :title="`Emails`" :hasBack="isSendEmail">
      <MBPButton v-if="!isSendEmail" label="Send a new email" class="action" @click="goToSendNewEmail()"></MBPButton>
    </PageToolbar>

    <router-view/>
  </section>
</template>

<script>


import PageToolbar from "../components/ui/PageToolbar";
import MBPButton from "../components/ui/controls/MBPButton";
export default {
  name: "Emails",
  components: {MBPButton, PageToolbar},
  data() {
    return {
      isSendEmail: false
    }
  },
  methods: {
    goToSendNewEmail () {
      this.$router.push('/emails/email');
      this.$router.go(1);
    }
  }
}
</script>

<style scoped lang="scss">


</style>
