<template>
  <section>
    <nav id="symbolsList">
      <div class="header">
        Found {{availableSymbols.length}}

        <select v-model="typeToShow">
          <option value="all">All</option>
          <option value="bo">Breakout</option>
          <option value="didntbo">Didn't breakout</option>
        </select>
      </div>
      <div v-for="(symbol, sIndex) in list" :key="symbol.symbol" class="navLink desktop symbol" @click="selectedSymbol = symbol.symbol">
        {{ symbol.symbol }} {{}}
        <span class="breakOut" v-if="symbol.breakOutCandle">B.O.</span>
        <span class="numOfPatterns">{{symbol.patterns}}</span>
      </div>
    </nav>

    <div id="chart">
      <div class="chartTools">
        <MBPButton @click="$refs.chart.toggleSR()" class="action small" label="Show/Hide S/R"/>
      </div>
      <div id="chartWrapper">
        <ChartTest ref="chart" :symbol="selectedSymbol" v-if="selectedSymbol" :key="selectedSymbol"/>
      </div>
    </div>

  </section>
</template>

<script>

import TradesService from '../services/trades.service';
import ChartTest from "./ChartTest";
import MBPButton from "../components/ui/controls/MBPButton";

export default {
  name: "Patterns",
  components: {MBPButton, ChartTest},
  data () {
    return {
      typeToShow: 'all',
      availableSymbols : [],
      isLoadingSymbols: true,
      selectedSymbol: null,
    }
  },
  async beforeMount() {

    await this.getSymbolsOfPatterns()
  },
  computed: {
    list () {

      if (this.typeToShow === 'all') return this.availableSymbols;
      if (this.typeToShow === 'bo') return this.availableSymbols.filter(symbol => symbol.breakOutCandle);
      if (this.typeToShow === 'didntbo') return this.availableSymbols.filter(symbol => !symbol.breakOutCandle);
    }
  },
  methods: {
    async getSymbolsOfPatterns () {

      this.availableSymbols = (await TradesService.getSymbolsOfPatterns())
          // .filter(symbol => !symbol.breakOutCandle);
    }
  }
}
</script>

<style scoped lang="scss">

.navLink {
  box-sizing: border-box;
}

.header {
  font-size: 14px;
  border-bottom: 1px solid var(--notficationBorderColor);
  padding: 5px 5px 5px 5px;
  margin: -5px -5px 0 -5px;
  background: var(--mbpGreenOpacity);
}

section {
  display: flex;
  align-items: flex-start;
}

#symbolsList {
  width: 160px;
  padding: 10px;
  box-sizing: border-box;
  border-right: 1px solid #000000;
  overflow-y: auto;
  height: calc(99vh);
}

#chart {
  height: calc(99vh);
  width: calc(100% - 100px);
}

.symbol {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.breakOut {
  color: var(--lightYellow);
  background: var(--lightYellowOpacity);
  padding: 2px 5px;
  box-sizing: border-box;
  margin: -2px -5px;
  border-radius: 5px;
}
.numOfPatterns {
  background: var(--opacityPurple);
  color: var(--purple);
  padding: 2px 5px;
  box-sizing: border-box;
  margin: -2px -5px;
  border-radius: 5px;
}

.chartTools {
  border-bottom: 1px solid var(--notficationBorderColor);
  padding: 10px;
}

#chartWrapper {
  height: calc(100vh - 35px);
}

</style>
