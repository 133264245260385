const PATH = '/trades';

export default {
    async getTrades() {

        return axios.get(`${PATH}`);
    },
    async getTrade(tradeId) {

        return axios.get(`${PATH}/${tradeId}`);
    },
    async createTrade(trade) {

        return axios.post(`${PATH}`, trade);
    },
    async updateTrade({tradeId, trade, followupIndex}) {

        return axios.put(`${PATH}/${tradeId}${followupIndex !== null ? '/' + followupIndex : ''}`, trade);
    },
    async deleteTrade(tradeId) {

        return axios.delete(`${PATH}/${tradeId}`);
    },
    async getSymbolsOfPatterns () {

        return axios.get(`${PATH}/patterns/symbols`);
    },
    async translate (content) {

        return axios.post(`${PATH}/translate`, content);
    }
}
