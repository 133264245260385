<template>
  <section>
    <slot/>
    <div class="overlay" ref="overlay" @click="$emit('close')"/>
  </section>
</template>

<script>
export default {
  name: "OverlayUI",
  props: ['bg']
}
</script>

<style scoped lang="scss">

  .overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 12;
    background: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(1px);
  }
</style>
