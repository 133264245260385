<template>
  <section>

    <DataGridTable :is-compact="true"
                   :key="tableKey"
                   :data="notifications"
                   :hovered-columns="true"
                   :loaded="wasLoaded"
                   :isLoading="isLoading"
                   :default-sort="defaultSort"
                   :heightElements="['topBar','title']"
                   :default-sort-direction="defaultSortDirection"
                   :fixed-col="0">
      <template #thead="{thClick, sortKey, sortDirection, xAxisScrolled}">
        <HeadCell v-for="(th, thIndex) in headers"
                  :sortKey="sortKey"
                  :sortDirection="sortDirection"
                  :sortValue="th.sortValue"
                  @sort="thClick(th.sortValue)">
          {{ th.label }}
        </HeadCell>
      </template>

      <template #tbody="{rows}">
        <tr v-for="(row, rowIndex) in rows" :key="`r-${rowIndex}`">
          <td @click="goToNotificationById(row._id)">{{ truncate(row.title) }}</td>
          <td @click="goToNotificationById(row._id)">{{row.symbols.join(', ')}}</td>
          <td @click="goToNotificationById(row._id)">{{ formatDate(row.timing) }}</td>
          <td>
            <div class="actionsTd">
              <i class="ri-delete-bin-line" @click="showDeleteTradeModal = row._id"/>
            </div>
          </td>
        </tr>
      </template>

    </DataGridTable>

  </section>
</template>

<script>
import DataGridTable from "../../../components/dataGrid/DataGridTable";
import {DateTime} from "luxon";
import HeadCell from "../../../components/dataGrid/HeadCell";

import NotificationsGridHeaders from "../../../data/NotificationsGridHeaders";

import NotificationsService from '../../../services/notifications.service';

export default {
  name: "NotificationsTable",
  components: {HeadCell, DataGridTable},
  data () {
    return {
      notifications: [],
      isLoading: false,
      wasLoaded: false,
      defaultSort: 'timing',
      tableKey: 1,
      defaultSortDirection: 1,
      headers: NotificationsGridHeaders
    }
  },
  beforeMount() {

    this.getTimedNotificationsList().then().catch();
  },
  methods: {
    truncate(text) {

      const splitText = text.split(/[ ]/g);
      return (splitText.length > 10) ? splitText.slice(0, 10).join(' ') + ' ... ...' : splitText.join(' ')
    },
    async getTimedNotificationsList () {

      this.notifications = await NotificationsService.getTimedNotificationsList();
    },
    formatDate(date) {

      return DateTime.fromISO(date).toFormat('DD HH:mm')
    },
    goToNotificationById (id) {

      this.$router.push(`/notifications/notification/${id}`);
      this.$router.go(1);
    },
  }
}
</script>

<style scoped lang="scss">

</style>
