<template>
  <div>

    <div id="topBar">
      <div id="searchWrapper" style="width: 100%">
        <i class="ri-search-2-line"/>
        <input type="search"
               style="width: 100%"
               ref="searchInput"
               :placeholder="'Search symbols'" v-model="searchQuery"/>

      </div>
    </div>
    <ModalPrompt v-if="showDeleteTradeModal !== null" @close="showDeleteTradeModal = null;">
      <template #title>
        <h3>Trade deletion</h3>
      </template>
      <template #content>
        <p>Are you sure you want to delete this trade?</p>
      </template>
      <template #actions>

        <div class="actions">
          <MBPButton label="Yes, delete trade" @click="deleteTrade()" class="danger"/>
          <MBPButton label="No, cancel" class="action" @click="showDeleteTradeModal = null"/>
        </div>
      </template>
    </ModalPrompt>


    <ModalPrompt v-if="showPublishModal !== null" @close="showPublishModal = null;">
      <template #title>
        <h3>Publish trade</h3>
      </template>
      <template #content>
        <p>Are you sure you want to make this trade public and available for MBP's users?</p>
      </template>
      <template #actions>

        <div class="actions">
          <MBPButton label="Yes, publish trade" class="danger" @click="togglePublicity(true)"/>
          <MBPButton label="No, cancel" class="action" @click="showPublishModal = null"/>
        </div>
      </template>
    </ModalPrompt>

    <ModalPrompt v-if="showUnPublishModal !== null" @close="showUnPublishModal = null;">
      <template #title>
        <h3>Un-publish trade</h3>
      </template>
      <template #content>
        <p>Are you sure you want to un-publish this trade and make it unavailable for MBP's users?</p>
      </template>
      <template #actions>

        <div class="actions">
          <MBPButton label="Yes, un-publish trade" class="danger" @click="togglePublicity(false)"/>
          <MBPButton label="No, cancel" class="action" @click="showUnPublishModal = null"/>
        </div>
      </template>
    </ModalPrompt>

    <BigLoader v-if="isLoading"/>

    <section v-else >
    <DataGridTable :is-compact="true"
                   :key="tableKey"
                   :data="finalList"
                   :hovered-columns="true"
                   :loaded="wasLoaded"
                   :isLoading="isLoading"
                   :default-sort="defaultSort"
                   :heightElements="['topBar']"
                   :default-sort-direction="defaultSortDirection"
                   :fixed-col="0">
      <template #thead="{thClick, sortKey, sortDirection, xAxisScrolled}">
        <HeadCell v-for="(th, thIndex) in headers"
                  :sortKey="sortKey"
                  :sortDirection="sortDirection"
                  :sortValue="th.sortValue"
                  @sort="thClick(th.sortValue)">
          {{ th.label }}
        </HeadCell>
      </template>

      <template #tbody="{rows}">
        <tr v-for="(row, rowIndex) in rows" :key="`r-${rowIndex}`">
          <td @click="goToTradeById(row._id)">{{ row.symbol }} <span style="color: var(--lightYellow); background: var(--lightYellowOpacity); padding: 3px; border-radius: 4px">({{row.numOfTrade}})</span></td>
          <td @click="goToTradeById(row._id)">{{ setStatus(row.status) }}</td>
<!--          <td @click="goToTradeById(row._id)" :class="{available: row.isPublic, unAvailable: !row.isPublic}">-->
<!--            {{ setPublicity(row.isPublic) }}-->
<!--          </td>-->
          <td @click="goToTradeById(row._id)">{{ formatDate(row.dateAdded) }} {{daysAgo(row.dateAdded)}}</td>
          <td @click="goToTradeById(row._id)">{{ row.lastUpdate ? formatDate(row.lastUpdate) : '' }} {{daysAgo(row.lastUpdate)}}</td>
          <td @click="goToTradeById(row._id)" :class="{up: row.priceChangeSinceCreation > 0, down: row.priceChangeSinceCreation < 0}">{{ priceLabel(row) }}</td>
          <td @click="goToTradeById(row._id)">{{ row.followups }}</td>
          <td>
            <div class="actionsTd">
              <i class="ri-delete-bin-line" @click="showDeleteTradeModal = row._id"/>
<!--              <i v-if="!row.isPublic" class="available ri-play-circle-line" @click="showPublishModal = row._id"/>-->
<!--              <i v-if="row.isPublic" class="unAvailable ri-stop-circle-line" @click="showUnPublishModal = row._id"/>-->
            </div>
          </td>
        </tr>
      </template>
    </DataGridTable>
    </section>

  </div>
</template>

<script>

//Comp
import MBPButton from "../../../components/ui/controls/MBPButton";
import DataGridTable from "../../../components/dataGrid/DataGridTable";
import HeadCell from "../../../components/dataGrid/HeadCell";

//Services
import TradeService from '../../../services/trades.service';

//Data
import Headers from '../../../data/TradeGridHeaders';

//Dep
import {DateTime} from 'luxon';
import ModalPrompt from "../../../components/ui/ModalPrompt";
import BigLoader from "../../../components/ui/BigLoader";

export default {
  name: "TradesTable",
  components: {BigLoader, ModalPrompt, DataGridTable, MBPButton, HeadCell},
  data() {
    return {
      searchQuery: null,
      showDeleteTradeModal: null,
      showUnPublishModal: null,
      showPublishModal: null,
      headers: Headers,
      tableKey: 0,
      isLoading: true,
      defaultSort: 'dateAdded',
      defaultSortDirection: -1,
      trades: []
    }
  },
  async beforeMount() {

    await this.getTrades();
  },
  computed: {
    finalList() {

      if (this.searchQuery !== null && this.searchQuery.toString().trim().length > 0) {

        const query = this.searchQuery.toUpperCase().trim().split(/[\, ]/g).map(symbol => symbol.trim());
        return this.trades.filter(trade => {

          return query.find(q => trade.symbol.indexOf(q) === 0);
        });
      }

      return this.trades;
    }
  },
  methods: {
    async deleteTrade () {

      try {
        await TradeService.deleteTrade(this.showDeleteTradeModal);
        this.getTrades().then().catch();
        this.showDeleteTradeModal = null;
      } catch (e) {
        alert(e.message);
      }
    },
    async togglePublicity (value) {

      try {
        await TradeService.updateTrade({tradeId: this.showUnPublishModal || this.showPublishModal, trade: {isPublic: value}});
        this.getTrades().then().catch();
        this.showUnPublishModal = null;
        this.showPublishModal = null;
      } catch (e) {
        alert(e.message);
      }
    },
    priceLabel (row) {
      if (row.priceChangeSinceCreation === null) return 'N\\A';
      return `${row.priceChangeSinceCreationFormat}`
    },
    daysAgo (date) {

      if (!date) return  '';
      const specificDate = DateTime.fromISO(date);
      const now = DateTime.now();
      const diffInDays = now.diff(specificDate, 'days').days;

      return `(${Math.round(diffInDays)} days ago)`
    },
    async getTrades () {

      this.trades = await TradeService.getTrades();
      this.isLoading = false;

    },
    goToTradeById (id) {

      this.$router.push(`/trades/trade/${id}`);
      this.$router.go(1);
    },
    formatDate(date) {

      return DateTime.fromISO(date).toFormat('DD')
    },
    setPublicity(boolean) {

      return boolean ? 'Yes' : 'No'
    },
    setStatus(status) {
      switch (status) {
        case 'patternFound':
          return 'Possible trade';
          break;

        case 'failed':
          return 'Failed';
          break;

        case 'closed':
          return 'Closed';
          break;

        case 'possibleBreakout':
          return 'Possible break out';
          break;
      }
    }
  }
}
</script>

<style scoped lang="scss">


.up {
  color: var(--green);
  background: var(--opacityGreen);
}

.down {
  color: var(--red);
  background: var(--opacityRed);
}

.available {
  background: var(--highlightColorOpacity) !important;
  color: var(--highlightColor);
}

.unAvailable {
  background: var(--lightYellowOpacity) !important;
  color: var(--lightYellow);
}

.actionsTd {
  display: flex;
  align-items: center;
  gap: .25rem;

  i {
    font-size: 18px;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background: var(--bgColor);

    }
  }
}

#searchWrapper {
  position: relative;
  z-index: 3;

  i {
    position: absolute;
    top: 50%;
    left: 10px !important;
    transform: translateY(-50%);
  }

  input {
    padding: 5px;
    border-radius: 50px;
    border: 2px solid var(--notficationBorderColor);
    width: 160px;
    transition: all .1s ease-in;
    appearance: none;
    padding-left: 30px !important;

    &:focus,
    &:active {
      outline: none;
    }

    &.autoWidth {
      width: auto;
    }

    @media(max-width: 660px) {
      width: 210px;
      margin-right: auto;
    }

    &:focus, &:active {
      border: 2px solid var(--highlightColor);
      background: var(--bgColor);
    }
  }
}

#topBar {
  padding: 5px 10px;
  border-bottom: 1px solid var(--notficationBorderColor);
}

</style>
