<template>
  <div class="chart" :style="{height: '93vh', width: '99%', margin: '0 auto'}" ref="chart"></div>
</template>

<script>

function calculateZigzag(ohlcvData, reversalPercentage, pivotLegs) {
  let pivots = [];
  let trend = null;
  let lastPivot = null;

  const percentageThreshold = reversalPercentage / 100;

  for (let i = pivotLegs; i < ohlcvData.length - pivotLegs; i++) {
    const currentHigh = ohlcvData[i].high;
    const currentLow = ohlcvData[i].low;
    const date = ohlcvData[i].date;
    if (trend === null) {
      // Identify initial trend direction
      if (currentHigh > ohlcvData[i - pivotLegs].high) {
        trend = 'up';
        lastPivot = currentLow;
        pivots.push({type: 'low', value: lastPivot, index: i - pivotLegs, date});
      } else if (currentLow < ohlcvData[i - pivotLegs].low) {
        trend = 'down';
        lastPivot = currentHigh;
        pivots.push({type: 'high', value: lastPivot, index: i - pivotLegs, date});
      }
    } else if (trend === 'up') {
      if ((currentLow < lastPivot) && ((lastPivot - currentLow) / lastPivot) >= percentageThreshold) {
        trend = 'down';
        lastPivot = currentHigh;
        pivots.push({type: 'high', value: lastPivot, index: i, date});
      } else if (currentHigh > lastPivot) {
        lastPivot = currentHigh;
        pivots[pivots.length - 1] = {type: 'low', value: lastPivot, index: i, date}; // Update the last pivot
      }
    } else if (trend === 'down') {
      if ((currentHigh > lastPivot) && ((currentHigh - lastPivot) / lastPivot) >= percentageThreshold) {
        trend = 'up';
        lastPivot = currentLow;
        pivots.push({type: 'low', value: lastPivot, index: i, date});
      } else if (currentLow < lastPivot) {
        lastPivot = currentLow;
        pivots[pivots.length - 1] = {type: 'high', value: lastPivot, index: i, date}; // Update the last pivot
      }
    }
  }

  console.log(pivots)
  return pivots;
}

class ZigZag {
  constructor(data, deviation = 5) {

    data = data.filter(point => point.high !== null && point.low !== null);
    this.data = data;
    this.deviation = deviation / 100;
    this.zigzag = [];
    this.peaks = [];
    this.troughs = [];
  }

  calculate() {
    let trend = 0;
    let lastPeak = {value: -Infinity, index: -1};
    let lastTrough = {value: Infinity, index: -1};

    for (let i = 0; i < this.data.length; i++) {
      const high = this.data[i].high;
      const low = this.data[i].low;
      const date = this.data[i].date;

      if (trend >= 0) {
        if (high > lastPeak.value) {
          lastPeak = {value: high, index: i, date, type: 'high'};
        } else if (low < lastPeak.value * (1 - this.deviation)) {
          this.zigzag.push(lastPeak);
          this.peaks.push(lastPeak);
          lastTrough = {value: low, index: i, date, type: 'low'};
          trend = -1;
        }
      } else {
        if (low < lastTrough.value) {
          lastTrough = {value: low, index: i, date, type: 'low'};
        } else if (high > lastTrough.value * (1 + this.deviation)) {
          this.zigzag.push(lastTrough);
          this.troughs.push(lastTrough);
          lastPeak = {value: high, index: i, date, type: 'high'};
          trend = 1;
        }
      }
    }

    // Add the last point
    if (trend >= 0) {
      this.zigzag.push(lastPeak);
      this.peaks.push(lastPeak);
    } else {
      this.zigzag.push(lastTrough);
      this.troughs.push(lastTrough);
    }

    return {
      zigzag: this.zigzag,
      peaks: this.peaks,
      troughs: this.troughs
    };
  }
}


import * as echarts from 'echarts';
import ChartService from '../../services/chart.service'
import CANDLES from '../../views/AAPLPrices';

let chartDrawing = null;

export default {
  name: "CandleChart",
  props: ['symbol'],
  data() {
    return {
      candles: null,
      patterns: [],
      positiveColor: 'rgb(49,169,130)',
      negativeColor: 'rgb(224,23,53)',
      chartOptions: null,
      chart: null
    }
  },
  async beforeMount() {

    try {
      await this.getChartData();
    } catch (e) {
      console.log(e)
    }
  },
  methods: {
    drawChart() {
      echarts.dispose(this.$refs.chart);

      const candles = this.candles;
      const dates = candles.map(item => item.date);
      const ohlc = candles.map(item => [item.open, item.close, item.low, item.high]);
      const volumes = candles.map(item => item.volume);


      const newZigZag = new ZigZag(candles, 3);
      const result = newZigZag.calculate();


      let highs = candles.filter(c => c.high !== null).map(c => c.high);
      let lows = candles.filter(c => c.low !== null).map(c => c.low);
      let maxHigh = Math.max(...highs);
      let maxLow = Math.min(...lows);
      const binsSize = (maxHigh - maxLow) / 25;

      let highestHigh = candles.find(high => high.high === maxHigh);
      const highestHighDate = new Date(highestHigh.date).getTime();
      let lowestLow = candles[0];
      let found = false;

      // let MA50Lower = candles[candles.length - 1].smas.ma50 <

      for (let i = candles.length - 1; i > -1; i--) {

        if (lowestLow.low > candles[i].low && candles[i - 1].low > candles[i].low) {
          lowestLow = candles[i];
          break;
        } else {
          lowestLow = candles[i];
        }
      }

      const lowestLowDate = new Date(lowestLow.date).getTime();

      let bins = {};

      for (let i = 0; i < 25; i++) {
        let bottomLimit = maxLow + (i * binsSize);
        let topLimit = maxLow + (i * binsSize) + binsSize;
        bins[i] = {latestLowestLow: false, bottomLimit, topLimit, candles: [], avg: (bottomLimit + topLimit) / 2}
      }

      let triangle = []
      let startT = null;

      candles.reverse().forEach(c => {

        for (let k in bins) {

          const isLowInBound = c.low >= bins[k].bottomLimit && c.low <= bins[k].topLimit;
          const isHighInBound = c.high >= bins[k].bottomLimit && c.high <= bins[k].topLimit;

          if (isHighInBound || isLowInBound) {
            if (c.date === lowestLow.date) {
              bins[k].latestLowestLow = true;
            }
            if (bins[k].latestLowestLow === true && (new Date(c.date).getTime() < highestHighDate && new Date(c.date).getTime() < lowestLowDate) && !startT && (isHighInBound || isLowInBound)) {
              // console.log('Start T', c, bins[k].bottomLimit)
              // startT = c;
              // triangle.push([c.date, bins[k].bottomLimit])
              // triangle.push([highestHigh.date, highestHigh.high])
              // triangle.push([lowestLow.date, lowestLow.low])
            }
            bins[k].candles.push(c)
          }
        }
      })

      console.log(triangle)

      // result.zigzag.forEach(c => {
      //
      //   for (let k in bins) {
      //
      //     if ((c.value >= bins[k].bottomLimit && c.value <= bins[k].topLimit)){
      //       if (c.date === lowestLow.date) bins[k].latestLowestLow = true;
      //       bins[k].candles.push(c)
      //     }
      //   }
      // })

      function findPercentileValue(arr, percentile) {
        if (percentile < 0 || percentile > 100) {
          throw new Error('Percentile must be between 0 and 100.');
        }

        // Sort the array in ascending order
        arr.sort((a, b) => a - b);

        // Calculate the index for the given percentile
        let index = Math.ceil((percentile / 100) * arr.length) - 1;

        // Return the value at that index
        return arr[index];
      }

      bins = Object.values(bins).sort((a, b) => b.candles.length - a.candles.length).filter(bin => bin.latestLowestLow)[0];
      if (!bins) {
        bins = [];
      } else {
        bins = [bins]
      }

      const highSupportDiff = bins[0] ? ((maxHigh - bins[0].avg) / bins[0].avg) * 100 : null;
      const volumeProfileStrength = bins.map(bin => bin.candles.length);
      const threshold = findPercentileValue(volumeProfileStrength, 90);
      // console.log('threshold', threshold)

      let markAreas = [];
      console.log('LL', lowestLow)

      function getClosest(arr, target) {

        return arr.map((obj) => {

          obj.highDiff = Math.abs(obj.close - target);
          // obj.lowDiff = Math.abs(obj.low - target);
          return obj;
        }).sort((a,b) => {

          return a.highDiff < b.highDiff
          // return ((a.highDiff + a.lowDiff) / 2) < ((b.highDiff + b.lowDiff) / 2)
          // return (a.lowDiff < b.lowDiff || a.highDiff < b.highDiff)
        });
      }

      bins = bins
          .filter(bin => bin.latestLowestLow)
          // .filter(bin => bin.candles.length >= threshold)
          .map((line, lineIdx) => {

            let candlesOnSupport = [];

            line.avg = lowestLow.low;
            line.candles?.forEach(c => {
              if (line.latestLowestLow === true && (new Date(c.date).getTime() < highestHighDate && new Date(c.date).getTime() < lowestLowDate)) {
                console.log('Start T', c, line.avg)
                candlesOnSupport.push(c);


              }
            });

            const closestToSupportStartCandle = getClosest(candlesOnSupport, line.avg);
            console.log('cs', closestToSupportStartCandle)
            if (closestToSupportStartCandle) {
              triangle.push([closestToSupportStartCandle[0].date, lowestLow.low]);
              triangle.push([highestHigh.date, highestHigh.high]);
              triangle.push([lowestLow.date, lowestLow.low]);
            }
            //
            // return [{
            //   name: `line${lineIdx}`,
            //   yAxis: line.avg + 0.05,   // Starting y-axis value
            //   xAxis: dates[0]   // Start from the first x-axis value
            // },
            //   {
            //     yAxis: line.avg - 0.05,   // Ending y-axis value
            //     xAxis: dates[dates.length - 1]   // Span to the last x-axis value
            //   }]

            console.log(line.candles)
            const markLine = {
              name: `line${lineIdx}`,
              yAxis: line.avg,
              // yAxis: line.candles.reduce((final, current) => {
              //
              //   final += (current.close + current.open) / 2;
              //   return final;
              // }, 0) / line.candles.length,
              lineStyle: {
                color: 'rgba(0,149,255, 0.5)',
                type: 'solid',
                width: 2
              },
              label: {
                formatter: 'Threshold'
              }
            };

            return markLine;
          });

      chartDrawing = echarts.init(this.$refs.chart);

      let lines = [];
      let pZigzag = [];

      this.patterns.forEach((pattern, index) => {

        let divideValue = pattern.components.resistance.startValue > 200 ? 5 : pattern.components.resistance.startValue > 50 ? 3 : 2;
        const startDate = new Date(pattern.components.resistance.startDate);
        const endDate = new Date(pattern.breakOutCandle ? pattern.breakOutCandle.date : pattern.components.resistance.endDate);
        const d = ((pattern.components.resistance.startValue - pattern.components.support.startValue) / pattern.components.resistance.startValue) / divideValue;


        let patternCandles = [...pattern.components.resistance.touchingCandles, ...pattern.components.support.touchingCandles].sort((a, b) => a.originalIndex - b.originalIndex);

        const newZigZag = new ZigZag(patternCandles, d * 100);
        pZigzag = newZigZag.calculate();


        const zigzagArray = pZigzag.zigzag.map(p => ({
          type: p.type,
          date: p.date,
          value: p.type === 'high' ? p.high : p.low,
          high: p.high,
          low: p.low
        }));

        // console.log(zigzagArray)

        const countTouches = zigzagArray.reduce((final, current) => {

          if (current.type === 'high') final.highs++;
          if (current.type === 'low') final.lows++;
          return final;
        }, {highs: 0, lows: 0});

        // console.log('count', countTouches)

        let isAlternating = true;
        let lastPointType = zigzagArray[0].type;

        for (let i = 1; i < zigzagArray.length; i++) {
          if (lastPointType === zigzagArray[i].type) {
            isAlternating = false;
            break;
          } else {
            lastPointType = zigzagArray[i].type;
          }
        }

        // console.log('Alternating', isAlternating)

        lines.push({
          name: `line${index}s`,
          type: 'line',
          // clip: true,
          data: [[pattern.components.support.startDate, pattern.components.support.startValue], [pattern.components.support.endDate, pattern.components.support.endValue]],
          lineStyle: {
            color: 'green',
            width: 2,
            dashOffset: 10
          }
        });

        lines.push({
          name: `line${index}r`,
          type: 'line',
          // clip: true,
          data: [[pattern.components.resistance.startDate, pattern.components.resistance.startValue], [pattern.components.resistance.endDate, pattern.components.resistance.endValue]],
          lineStyle: {
            color: 'red',
            width: 2,
            dashOffset: 10
          }
        });
      });

      this.chartOptions = {
        animation: false,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          },
          formatter: params => {

            const dataIndex = params[0]?.dataIndex || null;

            if (dataIndex !== null) {

              const point = this.candles[dataIndex];


              return point.open === null ? '' : `
              <div>Date: ${point.date}</div>
              <div>Open: ${point.open}</div>
              <div>High: ${point.high}</div>
              <div>Low: ${point.low}</div>
              <div>Close: ${point.close}</div>
              <div>Volume: ${point.volume?.toLocaleString()}</div>
            `;
            } else {
              return ''
            }
          }
        },
        xAxis: [
          {
            type: 'category',
            data: dates,
            show: true,
            boundaryGap: false,
            min: 'dataMin',
            max: 'dataMax',
            axisLine: {onZero: false},
            axisLabel: {
              formatter: value => {
                const date = new Date(value);
                return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
              }
            }
          },
          {
            type: 'category',
            data: dates,
            gridIndex: 1,
            axisLabel: {
              formatter: value => {
                const date = new Date(value);
                return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
              }
            }
          }
        ],
        dataZoom: [
          {
            left: '0%',
            moveOnMouseMove: true,
            type: 'inside',
            yAxisIndex: 0,
            filterMode: 'none',
            show: true,
            start: 0,
            end: 100
          },
          {
            type: 'inside',
            xAxisIndex: [0, 1],
            start: 20,
            end: 80,
            filterMode: 'none',
            show: true,
          },
          {
            show: true,
            filterMode: 'none',
            xAxisIndex: [0, 1],
            top: '95%',
            start: 20,
            end: 80
          }
        ],
        yAxis: [
          {
            scale: false,
            minorTick: {
              show: true
            },
            splitLine: {
              lineStyle: {
                color: '#333'
              }
            },
            axisLine: {show: true, onZero: true},
            splitArea: {
              show: false
            }
          },
          {
            scale: false,
            gridIndex: 1,
            splitNumber: 2
          }
        ],
        grid: [
          {
            containLabel: true,
            animation: false,
            left: '0%',
            right: '0%',
            height: '75%',
            top: '3%',
            xAxisIndex: 0,
          },
          {
            containLabel: true,
            backgroundColor: '#1f1d1e',
            borderColor: 'transparent',
            animation: false,
            show: true,
            left: '0%',
            right: '0%',
            top: '79.24%',
            height: '15%',
          }
        ],
        graphic: [{
          type: 'text',
          left: 'center',
          top: 'middle',
          style: {
            text: this.symbol,
            fill: 'rgba(250, 250, 250, 0.1)',
            fontSize: 120
          }
        }],
        series: [
          {
            name: 'zigzag',
            type: 'line',
            data: result.zigzag.map(point => [point.date, point.value]),
            boundaryGap: false,
            clip: true,
            lineStyle: {
              color: 'rgba(255,145,0,0)',
              width: 1
            }
          },
          // {
          //   name: 'zigzag',
          //   type: 'line',
          //   data: zz.zigzag.map(point => [point.date, point.value]),
          //   boundaryGap: false,
          //   clip: true,
          //   lineStyle: {
          //     color: 'rgba(94,0,255,0)',
          //     width: 3
          //   }
          // },
          {
            name: 'pzigzag',
            type: 'line',
            data: pZigzag?.zigzag?.map(point => [point.date, point.value]) ?? [],
            boundaryGap: false,
            clip: true,
            lineStyle: {
              color: 'rgba(8,218,238,0.5)',
              width: 3
            }
          },
          {
            name: 't',
            type: 'line',
            data: triangle,
            lineStyle: {
              color: 'rgba(181,8,238,0.5)',
              width: 3
            }
          },
          ...lines,
          {
            name: 'Candlestick',
            z: 1,
            type: 'candlestick',
            data: ohlc,
            clip: true,
            //boundaryGap: true,
            // markArea: {
            //   symbol: ['none', 'none'],
            //   data: bins
            // },
            markLine: {
              symbol: ['none', 'none'],
              data: bins
            },
            itemStyle: {
              color: this.positiveColor,
              color0: this.negativeColor,
              borderColor: this.positiveColor,
              borderColor0: this.negativeColor
            }
          },
          {
            name: 'Volume',
            type: 'bar',
            xAxisIndex: 1,
            yAxisIndex: 1,
            zlevel: 1,
            data: candles.map(price => {
              return {
                value: price.volume,
                itemStyle: {
                  color: price.close < price.open ? this.positiveColor : this.negativeColor
                }
              }
            }),
          }
        ]
      };

      new ResizeObserver(() => {
        chartDrawing.resize();
      }).observe(this.$refs.chart);

      chartDrawing.setOption(this.chartOptions, {notMerge: true});


    },
    async getChartData() {

      const {candles, patterns} = await ChartService.getChartBySymbol(this.symbol);
      this.candles = candles;
      this.patterns = patterns;

      try {

        this.$nextTick(() => {
          this.drawChart();
        })
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style scoped>

</style>
